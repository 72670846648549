<template>
  <loader v-if="loading" />
  <div v-else class="double-restocking">
    <div class="title">{{ $t('doubleRestocking.title') }}</div>
    <div class="legend">
      <div class="item">
        <div class="bullet red" />
        {{ $t('doubleRestocking.canceled') }}
      </div>
      <div class="item">
        <div class="bullet green" />
        {{ $t('doubleRestocking.active') }}
      </div>
      <div class="item">
        <div class="bullet grey" />
        {{ $t('doubleRestocking.calculatedDate') }}
      </div>
    </div>
    <div class="tools">
      <div class="search">
        <v-svg h="1rem" name="loupe" />
        <input v-model="searchTerm"
               type="text"
               class="input"
               :placeholder="$t('geoNomSelection.nomenclature.placeholderResearch')">
      </div>
      <div class="favorites-filter">
        <v-svg v-for="(type, i) in favTypes"
               :key="`fav-filter-${i}`"
               :class="{ active: activeFavoriteTypes.includes(type) }"
               h="1.2rem"
               :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
               @click="onFavoriteFilterClicked(type)" />
      </div>
    </div>
    <div class="chips">
      <div class="chip canceled-dr" @click="showCanceledDRPopup = true">{{ $t('doubleRestocking.canceledDR') }}</div>
      <div class="chip sites-without-dr" @click="showWithoutDRPopup = true">{{ $t('doubleRestocking.sitesWithoutDR') }}</div>
    </div>
    <div class="weeks-array">
      <div class="blank-site" />
      <div v-for="(week, i) in weeks" :key="i" :class="[{ current: i === 1 }, 'week']">{{ week }}</div>
    </div>
    <div class="double-restocking-array">
      <div class="th site">Magasins</div>
      <select v-model="activeFormat" class="format-select">
        <option v-if="!activeFormat" value="" disabled>
          {{ $t('formats.select') }}
          <v-svg h="1rem" name="arrow-up" />
        </option>
        <option v-else value="">{{ $t('formats.noone') }}</option>
        <option v-for="format in formats" :key="format" :value="format">{{ $t(`formats.${ format }`) }}</option>
      </select>
      <template v-for="(day, i) in days" :key="i">
        <div :class="[{ 'current-week': day.isCurrentWeek, sunday: (i + 1) % 7 === 0 }, 'th day']">
          {{ day.formatted }}
        </div>
        <div v-if="day.weekday % 7 === 0" class="th blank" />
      </template>
      <template v-for="(row, j) in filteredRows" :key="j">
        <div :class="[{'first-line': j === 0 }, 'td site first-cell-of-line']">
          <v-svg :key="`icon-${j}`"
                 h="1rem"
                 :name="getFavoriteTypeOfItem('geography', { site: parseInt(row.site) }) ? `${getFavoriteTypeOfItem('geography', { site: parseInt(row.site) })}-active` : 'star'"
                 @click="addOrUpdateFavorite('geography', { site: parseInt(row.site), siteLabel: row.siteLabel, siteSid: row.siteSid })" />
          <div class="label">
            {{ row.site }}
            <div class="bold">{{ ` • ${ row.siteLabel }` }}</div>
          </div>
        </div>
        <template v-for="(openingDate, k) in row.openingDates" :key="k">
          <div
            :class="['td day', { 'first-line': j === 0, sunday: (k + 1) % 7 === 0 }, cellClasses(row.site, openingDate)]"
            @click.self="onCellClick(row, openingDate)">
            <div v-if="clickedCell?.site === row.site && clickedCell?.openingDate?.day === openingDate.day"
                 class="cell-popup">
              <div class="infos">
                <div class="label">{{ $t('doubleRestocking.keyDay') }}</div>
                <div class="value">{{ $filters.formatKeyDay(openingDate.keyDay) }}</div>
                <div class="label">{{ $t(`doubleRestocking.${ openingDate.isRestocking ? 'calculatedDateMin' : 'DRDay' }`) }}</div>
                <div class="value">{{ $filters.formatDate(openingDate.isRestocking ? openingDate.calculatedDay : openingDate.specialOpeningDate, 'dd LLL y') }}
                </div>
              </div>

              <v-svg h="0.5rem" name="cross" @click.once="onCellReset()" />
              <div v-if="clickedCell.openingDate.keyDay && ($filters.userIsAdmin() || $filters.userIsReam())" class="delete-button" @click="clickedDeleteButton">
                <v-svg h="1rem" name="trash" />
                <span>Annuler le DR</span>
              </div>
            </div>
          </div>
          <div v-if="(k + 1) % 7 === 0" :class="['td day blank', { 'last-cell-of-line': k === row.openingDates?.length - 1, 'first-line': j === 0 }]" />
        </template>
      </template>
    </div>
  </div>
  <canceled-dr-popup v-if="showCanceledDRPopup" @close="showCanceledDRPopup = false" />
  <without-dr-popup v-if="showWithoutDRPopup" @close="showWithoutDRPopup = false" />
  <delete-dr-confirmation-popup v-if="showDeleteConfirmationPopup" :data="clickedCell" @close="showDeleteConfirmationPopup = false" @deleteDr="onCellReset"/>
</template>

<script>
  import { extendWithAuthorization, http } from '@/http'
  import useContextStore from '@/store/context.store'
  import { favoritesTypes } from '@/utils/CONF'
  import { removeAccents } from '@/utils/indicators.utils'
  import { DateTime } from 'luxon'
  import { computed, inject, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useToast } from 'vue-toastification'

  export default {
    name: 'DoubleRestocking',
    setup() {
      const toast = useToast()

      const showCanceledDRPopup = ref(false)
      const showWithoutDRPopup = ref(false)
      const showDeleteConfirmationPopup = ref(false)

      const contextStore = useContextStore()
      const { getFavoritesByTypes, period, getFavoriteTypeOfItem, addOrUpdateFavorite } = contextStore

      const getRandomInt = () => Math.floor(Math.random() * 28)

      const dateTimeWeeks = computed(() => {
        const current = DateTime.fromObject({ weekYear: period.value.year, weekNumber: period.value.weekNumber })
        return [current.minus({ week: 1 }), current, current.plus({ week: 1 }), current.plus({ week: 2 })]
      })

      const weeks = computed(() => dateTimeWeeks.value.map(week => `${ week.startOf('week').toFormat('dd LLL') } - ${ week.endOf('week').toFormat('dd LLL y') }`))

      const days = computed(() => [...Array(28).keys()].map(i => {
        const d = dateTimeWeeks.value[0].startOf('week').plus({ day: i })
        const isCurrentWeek = d.weekNumber === period.value.weekNumber && d.year === period.value.year
        return {
          iso: d.toISODate(),
          formatted: d.toFormat('ccc dd').slice(0, 2) + d.toFormat('ccc dd').slice(3),
          weekday: d.weekday,
          isCurrentWeek
        }
      }))

      const clickedCell = ref(undefined)

      const linkedCell = ref(undefined)

      const onCellClick = (row, day) => {
        linkedCell.value = undefined

        const siteData = { site: row.site, siteSid: row.siteSid, siteLabel: row.siteLabel, siteFormat: row.siteFormat }

        clickedCell.value = { ...siteData, openingDate: day }

        if (day.isRestocking !== 'N') {
          const linkedDay = row.openingDates.find(openingDate => !day.isRestocking ? day.specialOpeningDate === openingDate.day : day.calculatedDay === openingDate.day)
          linkedCell.value = { ...siteData, openingDate: linkedDay }
        }
      }

      const onCellReset = () => {
        clickedCell.value = undefined
        linkedCell.value = undefined
      }

      const cellClasses = (siteId, openingDate) => ({
        green: openingDate.isRestocking === 'O',
        red: openingDate.isRestocking === 'N',
        grey: !openingDate.isRestocking && openingDate.calculatedDay,
        active: (siteId === clickedCell.value?.site && openingDate?.day === clickedCell.value?.openingDate?.day) || (siteId === linkedCell.value?.site && openingDate.day === linkedCell.value?.openingDate?.day)
      })

      const rows = ref([])

      const loading = ref(false)

      const fetchRows = async() => {
        loading.value = true

        const extendedHttp = await extendWithAuthorization(http)
        await extendedHttp
          .get('indicators/double-restocking', {
            searchParams: {
              startDate: days.value?.[0].iso,
              endDate: days.value?.at(-1).iso
            }
          })
          .json()
          .then(res => rows.value = res)
          .catch(err => {
            toast.error('Une erreur est survenue lors du chargement des doubles réappro')
            console.error(err)
          })
        loading.value = false
      }

      const emitter = inject('emitter')
      emitter.on('reloadDRData', () => fetchRows())

      const searchTerm = ref(undefined)

      const activeFavoriteTypes = ref([])

      const favTypes = ref(favoritesTypes)

      const onFavoriteFilterClicked = type => {
        const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
        isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)
      }

      const activeFormat = ref('')

      const formats = ref(['HYP', 'PRO', 'DRI'])

      const filteredRows = computed(() => {
        let r = rows.value.slice()

        if (activeFavoriteTypes.value?.length) r = r.filter(row => getFavoritesByTypes('geography', activeFavoriteTypes.value).some(favoriteItem => parseInt(favoriteItem.site) === parseInt(row.site)))
        if (activeFormat.value?.length) r = r.filter(row => row.siteFormat === activeFormat.value)
        if (searchTerm.value?.length) r = r.filter(row => removeAccents(`${ row.site } - ${ row.siteLabel }`).includes(removeAccents(searchTerm.value)))

        return r
      })

      const route = useRoute()

      watch(() => ({ ...route.query }), () => {
        if (route.name === 'DoubleRestocking') fetchRows()
      })

      const clickedDeleteButton = () => {
        showDeleteConfirmationPopup.value = true
      }

      fetchRows()

      return {
        activeFormat,
        showCanceledDRPopup,
        showWithoutDRPopup,
        formats,
        onCellReset,
        getFavoriteTypeOfItem,
        addOrUpdateFavorite,
        clickedCell,
        onCellClick,
        cellClasses,
        getRandomInt,
        weeks,
        days,
        searchTerm,
        filteredRows,
        loading,
        activeFavoriteTypes,
        favTypes,
        onFavoriteFilterClicked,
        showDeleteConfirmationPopup,
        clickedDeleteButton
      }
    }
  }
</script>

<style scoped lang="scss">
  .double-restocking {
    width: 100%;
    display: flex;
    flex-direction: column;
    @extend %secondary-font;
    font-size: $font-size-small;
    border: 1px solid #A6A6A6;
    border-radius: 16px;
    position: relative;
    margin: 1rem 0;

    .title, .tools, .legend {
      position: absolute;
      background-color: white;
      z-index: 11;
      padding: 0 0.5rem;
    }

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-extra-big;
      top: -27px;
      left: 1rem;
    }

    .legend {
      top: 22px;
      left: 1rem;
      display: flex;
      font-size: $font-size-tiny;

      .item {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;

        .bullet {
          width: 10px;
          height: 7px;
          border-radius: 5px;
          margin-right: 0.3rem;

          &.red { background-color: #E75858; }

          &.green { background-color: #98DD91; }

          &.grey { background-color: #C6C6C6; }
        }
      }
    }

    .tools {
      display: flex;
      align-items: center;
      top: -11px;
      left: 20rem;

      .search {
        display: flex;
        align-items: center;
        margin-right: 0.8rem;
        border-right: 2px solid #A6A6A6;

        .svg-loupe {
          color: #808080;
        }

        input[type='text'] {
          width: 90px;
          border: none;
          outline: none;
          font-size: $font-size-small;
          @extend %secondary-font--bold;
          color: #808080;
        }
      }

      .favorites-filter {
        display: flex;
        align-items: center;

        .v-svg {
          color: #808080;
          margin-right: 0.2rem;
          cursor: pointer;
        }
      }
    }

    .chips {
      position: absolute;
      top: -10px;
      right: 2.5rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;

      .chip {
        border-radius: 13px;
        border: none;
        background-color: var(--app-color);
        color: white;
        padding: 1px 7px;
        margin-left: 0.3rem;
        cursor: pointer;
      }
    }

    .weeks-array {
      width: 100%;
      height: 100%;
      padding: 2rem 2rem 0 2rem;
      display: grid;
      grid-template-columns: 30% repeat(4, 1fr);

      .week {
        text-align: left;
        margin-left: 0.5rem;
        text-transform: capitalize;
        color: #787878;

        &.current {
          @extend %primary-font--bold;
          color: black;
        }
      }
    }

    .double-restocking-array {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 2rem;
      display: grid;
      grid-template-columns: 30% repeat(32, 1fr);
      align-items: flex-end;

      .format-select {
        position: absolute;
        left: 10rem;
        top: 1rem;
        background-color: transparent;
        border: none;
        padding: 0 1em 0 0;
        margin: 0;
        @extend %primary-font--bold;
        font-size: inherit;
        cursor: pointer;
        line-height: inherit;
        outline: none;

        option {
          @extend %primary-font;
          cursor: pointer;
        }
      }

      .th {
        border-bottom: 1px solid #404040;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 12;
        background-color: white;
        height: 40px;

        &.day {
          word-spacing: 1000px;
          text-align: center;
          color: #787878;
        }

        &.site {
          padding-bottom: 5px;
          @extend %primary-font--bold;
          display: flex;
          align-items: flex-end;
        }

        &.current-week { color: black; }
      }

      .site {
        padding-left: 1rem;

        .label {
          display: flex;
          align-items: center;
          margin-left: 0.2rem;

          .bold {
            @extend %primary-font--bold;
            margin-left: 0.2rem;
          }
        }
      }

      .day {
        &:not(.sunday, .blank) { border-right: 1px solid #787878; }
      }

      .blank {
        border-right: none;
      }

      //each odd line (every cell in one line + 1 cell due to .select-item)
      @for $i from 35 through 67 {
        .td:nth-child(66n + #{$i}) {
          background-color: #F6EEE9;

          &.first-cell-of-line {
            border-radius: 10px 0 0 10px;

            &.first-line { border-radius: 0 0 0 10px; }
          }

          &.last-cell-of-line {
            border-radius: 0 10px 10px 0;

            &.first-line { border-radius: 0 0 10px 0; }
          }
        }
      }

      .td {
        height: 30px;
        display: flex;
        align-items: center;
        pointer-events: none;
        position: relative;

        .v-svg {
          cursor: pointer;
          pointer-events: auto;
        }

        &.green, &.grey {
          cursor: pointer;
          pointer-events: auto;
        }

        &.green {
          background-color: #98DD91;

          &.active {
            box-shadow: inset 0 4px 4px rgba(54, 116, 43, 0.3), inset 4px -4px 5px rgba(54, 116, 43, 0.3);
          }
        }

        &.grey {
          background-color: #C6C6C6;

          &.active {
            box-shadow: inset 0 4px 4px rgba(120, 120, 120, 0.3), inset 4px -4px 5px rgba(120, 120, 120, 0.3);
          }
        }

        &.red { background-color: #E65858; }

        .cell-popup {
          position: absolute;
          top: 30px;
          left: 100%;
          width: max-content;
          background-color: white;

          padding: 0.5rem;
          cursor: default;
          box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
          border-radius: 5px;
          z-index: 1;

          .infos {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .value {
              @extend %primary-font--bold;
            }
          }

          .svg-cross {
            position: absolute;
            top: 1px;
            right: 8px;
          }

          .delete-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 1px;
            cursor: pointer;
            align-items: center;
            margin-top: 0.5rem;

            background: #BC8A5F;
            border-radius: 10px;

            span {
              color: white;
            }

          }
        }
      }
    }
  }
</style>
